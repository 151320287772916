<template>
  <div class="issues-main">
    <v-row justify="center" class="issues-main-header">
      <h2>
        <a :href="getDoiLinkForIssue()" class="issues-main-header">
          {{$t('ufj') + '. ' + $route.params.year + '. Volume: ' + $route.params.vol + '. Issue: ' + $route.params.is}}
        </a>
      </h2>
    </v-row>
    <v-row
      v-for="(article, index) in articles"
      :key="index"
      style="margin-top: 4%"
    >
      <v-card flat class="issue-card">
        <v-card-title class="issue-card-title">
          {{ article.title }}
        </v-card-title>
        <v-card-subtitle class="issue-card-subtitle">
            <br>
            {{ article.authors }}
        </v-card-subtitle>
        <v-card-text>
          <span>{{'pp. ' + article.page }}</span>
          <br>
          <span>{{ article.doi }}</span>
        </v-card-text>

        <div class="issue-card-action">
          <v-btn
            class="issue-card-action-button"
            color="primary"
            @click="loadArticle(article)"
          >
            {{$t('aboutArticle')}}
          </v-btn>
          <v-btn
            class="go-to-issue-card-action-button"
            color="teal accent-3"
            :href="getDoiLinkForArticle(article.doi)"
          >
            {{$t('goToArticle')}}
          </v-btn>
        </div>
      </v-card>
    </v-row>
  </div>
</template>

<script>
import FileLoader from "../utils/FileLoader";

export default {
  name: "IssuesList",
  data: () => ({
    contentData: {}
  }),

  computed: {
    articles () {
      const { year, vol, is } = this.$route.params
      return this.contentData[`${year}.${vol}.${is}`] || []
    }
  },

  async mounted (){
    const fileLoader = new FileLoader()
    this.contentData = await fileLoader.Load('complex.json')
  },

  methods: {
    loadArticle (article) {
      if (!article) return
      if (!article.doi) return // TODO Handle this

      this.$router.push({ path: '/article', query: { doi: article.doi.replace('DOI: ', '').trim() } })
    },

    getDoiLinkForArticle (doiString) {
      if (!doiString)
        return ''

      const doiNumber = doiString.split(':')[1]
      if (!doiNumber)
        return ''

      return 'https://doi.org/' + doiNumber.trim()
    },

    getDoiLinkForIssue () {
      const { year, vol, is } = this.$route.params
      return `https://doi.org/10.24263/2310-1008-${year}-${vol}-${is}`
    }
  }
}
</script>

<style scoped>
.issues-main {
  margin-top: 4%;
  margin-bottom: 4%;
}

.issue-card {
  padding: 9px;
  min-width: 100%;
  filter: drop-shadow(-5.3846px 5.19231px 10.3846px rgba(19, 39, 96, 0.10)) drop-shadow(0px 2px 5px rgba(19, 39, 96, 0.11));
}

.issue-card-title {
  padding-bottom: 2.3%;
  line-height: 24px;
  font-weight: 900;
  word-break: break-word;
}

.issue-card-subtitle {
  color: black !important;
}

.issue-card-action {
  padding-left: 16px;
  padding-bottom: 16px;
}

.issue-card-action-button {
  padding: 1% 3% !important;
}

.go-to-issue-card-action-button{
  padding: 1% 3% !important;
  margin-left: 3%;
}

.issues-main-header {
  text-decoration: none;
  color: black;
}
</style>
<template>
  <main-body>
    <IssuesList/>
  </main-body>
</template>

<script>
import mainBody from "../components/MainBody";
import IssuesList from "@/components/IssuesList";
export default {
  name: "Issues",
  components: {mainBody,IssuesList}
}
</script>

<style scoped>

</style>